// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import {
  ApartmentOutlined,
  ApiOutlined,
  DeploymentUnitOutlined,
  FileTextOutlined,
  SecurityScanOutlined,
  SolutionOutlined,
  TabletOutlined,
  TeamOutlined,
  UserOutlined,
  ToolOutlined,
  CarOutlined,
  FileWordOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';
import { Permissions } from 'shared';

// // icons
// const icons = {
//   ChromeOutlined,
//   QuestionOutlined,
//   DeploymentUnitOutlined
// };

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other: NavItemType = {
  id: 'other',
  title: '',
  type: 'group',
  children: [
    {
      id: 'org-structure',
      title: 'Оргструктура',
      type: 'collapse',
      icon: ApartmentOutlined,
      breadcrumbs: true,
      children: [
        {
          id: 'roles/list',
          title: 'Роли',
          type: 'item',
          url: '/roles/list',
          icon: SecurityScanOutlined,
          permission: Permissions.MANAGE_COMPANIES_ROLES
        },
        {
          id: 'companies/list',
          title: 'Точки продаж',
          type: 'item',
          url: '/companies/list',
          icon: DeploymentUnitOutlined,
          permission: Permissions.MANAGE_COMPANIES
        },
        {
          id: 'users-groups/list',
          title: 'Группы',
          type: 'item',
          url: '/users-groups/list',
          icon: TeamOutlined,
          permission: Permissions.MANAGE_COMPANY_GROUPS
        },
        {
          id: 'directions/list',
          title: 'Направления',
          type: 'item',
          url: '/directions/list',
          icon: TeamOutlined,
          permission: Permissions.MANAGE_COMPANY_GROUPS
        },
        {
          id: 'cars/list',
          title: 'Автомобили',
          type: 'item',
          url: '/cars/list',
          icon: CarOutlined,
          permission: Permissions.MANAGE_COMPANIES
        },
        {
          id: 'employee/list',
          title: 'Сотрудники',
          type: 'item',
          url: '/employee/list',
          icon: UserOutlined,
          permission: Permissions.MANAGE_COMPANY_EMPLOYEE
        },
        {
          id: 'document-templates/list',
          title: 'Шаблоны документов',
          type: 'item',
          url: '/document-templates/list',
          icon: FileWordOutlined,
          permission: Permissions.MANAGE_COMPANY_EMPLOYEE
        }
      ]
    },
    {
      id: 'clients',
      title: 'Клиенты',
      type: 'collapse',
      icon: SolutionOutlined,
      breadcrumbs: true,
      children: [
        {
          id: 'clients/list',
          title: 'Активные',
          type: 'item',
          url: '/clients/list',
          permission: Permissions.MANAGE_CLIENTS
        },
        {
          id: 'account-profile',
          title: 'Архив',
          type: 'item',
          url: '/clients/archive',
          permission: Permissions.MANAGE_CLIENTS
        }
      ]
    },
    {
      id: 'callback',
      title: 'Колбек',
      type: 'collapse',
      icon: ApiOutlined,
      breadcrumbs: true,
      children: [
        {
          id: 'callback',
          title: 'Настройка и история',
          type: 'item',
          url: '/callback',
          icon: FileTextOutlined,
          permission: Permissions.MANAGE_COMPANIES_ROLES
        }
      ]
    },
    {
      id: 'companies-groups',
      title: 'Группы компаний',
      type: 'item',
      url: '/companies-groups',
      icon: ToolOutlined,
      permission: Permissions.MANAGE_COMPANIES_GROUPS
    },
    {
      id: 'self-service',
      title: 'Самообслуживание',
      type: 'item',
      icon: TabletOutlined,
      url: '/self-service-info',
      permission: Permissions.SELF_SERVICE_INFO
    }
  ]
};

export default other;
